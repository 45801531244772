import '../styles/globals.css';
import { ChakraProvider, Button, Center } from '@chakra-ui/react';
import NavBar from '../components/NavBar';
import LandingPage from '../components/LandingPage';

function MyApp({ Component, pageProps }) {
    return (
        <ChakraProvider>
            <Component {...pageProps} />
        </ ChakraProvider>
    );
}

export default MyApp;
/*

// _app.js v.0.0.69.5
//
// CHANGELOG from v.0.0.69.4 to v.0.0.69.5
// 1. Added retry mechanism for UET tag initialization.
// 2. Enhanced logging for UET tag status.

import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';

const appVerNum = "_app.js v.0.0.69.5";

function MyApp({ Component, pageProps }) {
    const router = useRouter();

    useEffect(() => {
        console.log(`[${appVerNum}] Initializing app`);
        const handleRouteChange = () => {
            if (typeof window.uetq !== 'undefined') {
                window.uetq.push('pageLoad');
                console.log(`[${appVerNum}] UET pageLoad event triggered on route change`);
            } else {
                console.warn(`[${appVerNum}] UET not initialized on route change`);
            }
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => router.events.off('routeChangeComplete', handleRouteChange);
    }, [router.events]);

    return (
        <>
            <Head>
                <title>HEAT Freight</title>
            </Head>
            <Script
                id="microsoft-uet-tag"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            (function(w,d,t,r,u){
              var f,n,i;
              w[u]=w[u]||[];
              f=function(){
                var o={ti:"97176155", enableAutoSpaTracking: true};
                o.q=w[u];
                w[u]=new UET(o);
                w[u].push("pageLoad");
                console.log("[${appVerNum}] UET initialized and pageLoad event pushed");
              };
              n=d.createElement(t);
              n.src=r;
              n.async=1;
              n.onload=n.onreadystatechange=function(){
                var s=this.readyState;
                if(!s || s==="loaded" || s==="complete"){
                  f();
                  n.onload=n.onreadystatechange=null;
                }
              };
              n.onerror=function(){
                console.error("[${appVerNum}] UET script failed to load");
              };
              i=d.getElementsByTagName(t)[0];
              i.parentNode.insertBefore(n,i);
            })(window,document,"script","//bat.bing.com/bat.js","uetq");

            // Retry mechanism for UET tag
            var retryCount = 0;
            var maxRetries = 5;
            var retryInterval = 2000; // 2 seconds
            function checkUET() {
              if (typeof window.uetq === 'undefined' && retryCount < maxRetries) {
                console.warn("[${appVerNum}] UET tag not detected, retrying in " + retryInterval + "ms");
                setTimeout(checkUET, retryInterval);
                retryCount++;
              } else if (retryCount >= maxRetries) {
                console.error("[${appVerNum}] UET tag failed to load after " + maxRetries + " retries");
              } else {
                console.log("[${appVerNum}] UET tag successfully loaded");
              }
            }
            setTimeout(checkUET, 1000);
          `,
                }}
            />
            <Component {...pageProps} />
        </>
    );
}

export default MyApp;
*/